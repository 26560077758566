export const environment = {
    production: false,
    apiUrl: 'https://fordisg-qa-api-app.azurewebsites.net/v1',
    contentJsonPath: '/assets/content-qa.json',
    useADFS: false,
    issuer: '',
    clientId: '',
    scopes: '',
    outputErrors: true,
    useExternalAuth: false,
    suffixUrl: 'home',
    ampli_key: '06087e0bec4a00f55618b50bad4eebba'
};
